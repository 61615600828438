export const newsContent = [

  {id: 26,
    date: "Summer 2024",
    title: "Amit Shenoy Wins PEAK Experience Base Camp Award for Summer 2024",
    summary: "Amit Shenoy’25 Wins the PEAK AWARD for his research titled, 'Extracting the Binding Affinities between Various Viral Glycans and MBL via Molecular Docking.'",
    img: "amit",
    alt: "Amit is wearing a blue striped tie and light blue collared shirt.",
    url: "https://undergraduate.northeastern.edu/research/news/summer-2024-peak-awardees/",
  },
  {
    id: 25,
    date: "Summer 2024",
    title:
      "Ann Titus Wins PEAK AWARD for Summer 2024",
    summary:
      "Ann Titus COE’25 wins the PEAK AWARD for her research titled, 'Modeling the Vapor-Liquid Equilibrium Curve of Pentadecanoic Acid Using Gibbs Ensemble Monte Carlo.'",
    img: "ann2",
    alt: "A photo of Ann wearing a black shirt.",
    url: "https://undergraduate.northeastern.edu/research/news/peak_fall_2023/",
  },
  {
    id: 24,
    date: "MAR 12, 2024",
    title:
      "Minkara Receives NSF CAREER Award to Improve Immune Response with AI",
    summary:
      "BioE Assistant Professor Mona Minkara was awarded a $827,000 NSF CAREER award for 'Decoding the Code of Glycan-Collectin Interactions: Computational Engineering of Surfactant Proteins for Tailored Glycan Recognition.'",
    img: "NSF2",
    alt: "NSF career award logo",
    url: "https://coe.northeastern.edu/news/minkara-receives-nsf-career-award-to-improve-immune-response-with-ai/",
  },
  {
    id: 1,
    date: "SEP 11, 2023",
    title:
      "Amanda Ferrante Wins ASCENT AWARD at Huskies Tackle Exciting PEAK Projects for Fall of 2023",
    summary:
      "Amanda Ferrante COE’26 wins ASCENT AWARD for her research titled, “Elucidating the Effects of Cholesterol on the Function of Surfactant Protein C using Atomistic Molecular Dynamics.”",
    img: "amanda",
    alt: "A photo of Amanda wearing a black shirt.",
    url: "https://undergraduate.northeastern.edu/research/news/peak_fall_2023/",
  },
  {
    id: 2,
    date: "SEP 11, 2023",
    title:
      "Ann Titus Wins the BASE CAMP AWARDS at Huskies Tackle Exciting PEAK Projects for Fall of 2023",
    summary:
      "Ann Titus COE’26 wins the BASE CAMP AWARDS for her research titled, “Loading of Paclitaxel in the Lamellar Phase of a Nonionic Surfactant and Drug Delivery Applications: Monte Carlo Simulation.”",
    img: "ann2",
    alt: "A photo of Ann wearing a black shirt.",
    url: "https://undergraduate.northeastern.edu/research/news/peak_fall_2023/",
  },
  {
    id: 3,
    date: "AUG 23, 2023",
    title: "Deng Li Presented His Research at 2023 Chemistry Spotlight @Pfizer",
    summary:
      "Deng Li presented his research titled 'Computational Modeling of the Binding Mechanisms of Surfactant Protein D (SP-D) With Glycans' as an Invited Poster at 2023 Chemistry Spotlight @Pfizer.",
    img: "dengNews",
    alt: "A photo of Deng wearing a black Levi's shirt underneath a blue jean shirt.",
    url: "",
  },
  {
    id: 4,
    date: "JUN 16, 2023",
    title:
      "Aastha Mahapatra Wins Northeastern Bioengineering Research Symposium Award",
    summary:
      "Aastha Mahapatra wins Northeastern Bioengineering Research Symposium Award for her research titled 'Molecular Docking Studies of Surfactant Protein A (SP-A) With Surface Glycans on Pathogens.'",
    img: "aasthaNews",
    alt: "A photo of Aastha and Mona standing next to a science presentation.",
    url: "",
  },
  {
    id: 5,
    date: "MAR 10, 2023",
    title: "We Need People with Different Perspectives to Solve Problems",
    summary:
      "BioE Assistant Professor Mona Minkara speech at the United Nations about the need for blind people in the field of science earned her a standing ovation.",
    img: "monaNews1",
    alt: "A photo of Mona looking to the left wearing a white collar shirt and gray checkered blazer.",
    url: "https://coe.northeastern.edu/news/we-need-people-with-different-perspectives-to-solve-problems/",
  },
  {
    id: 6,
    date: "MAR 02, 2023",
    title:
      "Deng Li's Bioengineering Conference Travel Award from Northeastern University was approved",
    summary:
      "Deng Li's Bioengineering Conference Travel Award from Northeastern University was approved.",
    img: "dengNews",
    alt: "A photo of Deng wearing a black Levi's shirt underneath a blue jean shirt.",
    url: "",
  },
  {
    id: 7,
    date: "MAR 01, 2023",
    title: "Celebrating National Engineers Week 2023",
    summary:
      "The College of Engineering celebrated National Engineers Week with events including a ‘Break the Mold’ Fireside Chat with award-winning engineer and commentator Dr. Shini Somara and Dean Gregory Abowd, Cookies with the Dean event, Breakout sessions with Shini Somara, and an 'Engineering for Everyone Expo' hosted by the Center for STEM Education.",
    img: "conferenceNews",
    alt: "A woman speaks on stage in front of an audience at a conference.",
    url: "https://coe.northeastern.edu/news/celebrating-national-engineers-week-2/",
  },
  {
    id: 8,
    date: "FEB 16, 2023",
    title:
      "Students With Blindness Empowered to Explore Chemistry Through SEPA Project",
    summary:
      "BioE Assistant Professor Mona Minkara was featured in the NIH Biomedical Beat article “Students With Blindness Empowered to Explore Chemistry Through SEPA Project.”",
    img: "mona",
    alt: "A headshot of Mona wearing a purple and tan headscarf and a black blazer.",
    url: "https://biobeat.nigms.nih.gov/2023/02/students-with-visual-impairments-empowered-to-explore-chemistry-through-sepa-project/",
  },
  {
    id: 9,
    date: "JAN 07, 2023",
    title:
      "Deng Li's Ph.D. Network Travel Funding from Northeastern University was approved",
    summary:
      "Deng Li's Ph.D. Network Travel Funding from Northeastern University was approved. The Bioengineering Conference Travel Award is a competitive award for BIOE PhD students who have shown outstanding contributions to their lab and the Department by sharing their research through an oral presentation or poster at a conference. Each Bioengineering student is eligible to apply for a $500 travel stipend twice per calendar year to a conference where they are presenting an oral presentation or poster.",
    img: "dengNews",
    alt: "A photo of Deng wearing a black Levi's shirt underneath a blue jean shirt.",
    url: "https://bioe.northeastern.edu/coe-research/travel-award-winners/",
  },
  {
    id: 10,
    date: "DEC 20, 2022",
    title: "Professor Minkara Honored with Plaque on Alumni Wall at Wellesley College",
    summary: "Mona Minkara was honored with a plaque on the alumni wall at the newly build Science Center at Wellesley College.",
    img: "monaPlaque",
    alt: "A headshot of Mona wearing a purple and tan headscarf and a black blazer.",
    url: "https://twitter.com/mona_minkara/status/1580255445419032576",
  },
  {
    id: 11,
    date: "OCT 07, 2022",
    title: "Announcing Fall 2022 PEAK Experiences Awardees",
    summary:
      "Several engineering students and science students mentored by COE faculty are recipients of Northeastern’s Fall 2022 PEAK Experiences Awards. This extraordinary group of students is taking on a range of exciting projects, from exploring axolotl limb regeneration to building a snake-inspired robot to understanding accessibility on Broadway.",
    img: "peakNews",
    alt: "A birds-eye view of the ISEC center featuring the double-helix staircase.",
    url: "https://coe.northeastern.edu/news/announcing-fall-2022-peak-experiences-awardees/",
  },
  {
    id: 12,
    date: "OCT 04, 2022",
    title:
      "Deng Li Wins ACCESS Credits That Can Access the Computational Resources",
    summary:
      "Deng Li wins ACCESS Credits that can access the computational resources from several High Performance Computing Centers including NCSA Delta GPU and NCSA Delta CPU.",
    img: "dengNews",
    alt: "A photo of Deng wearing a black Levi's shirt underneath a blue jean shirt.",
    url: "https://allocations.access-ci.org/",
  },
  {
    id: 13,
    date: "Sep 08, 2022",
    title:
      'Deng Li and Mona S Minkara Published on PubMed "Elucidating the Enhanced Binding Affinity of a Double Mutant SP-D With Trimannose on the Influenza A Virus Using Molecular Dynamics"',
    summary:
      'Deng Li and Mona S Minkara published on PubMed "Elucidating the Enhanced Binding Affinity of a Double Mutant SP-D With Trimannose on the Influenza A Virus Using Molecular Dynamics."',
    img: "dengNews",
    alt: "A photo of Deng wearing a black Levi's shirt underneath a blue jean shirt.",
    url: "https://pubmed.ncbi.nlm.nih.gov/36097510/",
  },
  {
    id: 14,
    date: "AUG 22, 2022",
    title:
      "This Technology Makes Data Accessible to Blind and Visually Impaired People",
    summary:
      "BioE Assistant Professor Mona Minkara was featured in the NPR podcast “This Technology Makes Data Accessible to Blind and Visually Impaired People.”",
    img: "mona",
    alt: "A headshot of Mona wearing a purple and tan headscarf and a black blazer.",
    url: "https://www.npr.org/2022/08/19/1118521010/this-technology-makes-data-accessible-to-blind-and-visually-impaired-people/",
  },
  {
    id: 15,
    date: "JUL 13, 2022",
    title: "BioE Alum Wins 2022 Innovator Award",
    summary:
      "Bioengineering alumna Samantha Johnson, E’21, ME’21, was the first place winner of the 2022 Innovator Awards given out by Northeastern’s Women Who Empower in the young alumnae graduate category, which she will use to develop a robotic arm that can sign in ASL.",
    img: "bioAlumNews",
    alt: "An image of Samantha next to a robotic arm.",
    url: "https://coe.northeastern.edu/news/bioe-alum-wins-2022-innovator-award/",
  },
  {
    id: 16,
    date: "JUN 07, 2021",
    title:
      "Catherine Zhou wins Base Camp Award of Summer 2021 PEAK Experiences Award",
    summary:
      "Catherine Zhou wins Base Camp Award of Summer 2021 PEAK Experiences Award",
    img: "catherine",
    alt: "A photo of Catherine wearing a gray sweater vest over a white collared shirt.",
    url: "https://coe.northeastern.edu/news/summer-2021-peak-experiences-awardees/",
  },
  {
    id: 17,
    date: "JUN 07, 2021",
    title:
      "Tyler Locke wins Ascent Award of Summer 2021 PEAK Experiences Award",
    summary:
      "Congratulations! Tyler Locke on winning the Summer 2021 PEAK Experiences Award.",
    img: "tylerNews",
    alt: "A photo of Tyler wearing a red checkered collared shirt.",
    url: "https://coe.northeastern.edu/news/summer-2021-peak-experiences-awardees/",
  },
  {
    id: 18,
    date: "JUN 07, 2021",
    title:
      "Zachary Tamweber wins Ascent Award of Summer 2021 PEAK Experiences Award",
    summary:
      "Congratulations! Zachary Tamweber on winning Ascent Award of the Summer 2021 PEAK Experiences Award.",
    img: "zachary",
    alt: "A photo of Zachary wearing a gray collar shirt.",
    url: "https://coe.northeastern.edu/news/summer-2021-peak-experiences-awardees/",
  },
  {
    id: 19,
    date: "JUN 01, 2020",
    title: "Jake Duffy wins Summer 2020 PEAK Experiences Award",
    summary:
      "Congratulations! Jake Duffy on winning the Summer 2020 PEAK Experiences Award.",
    img: "jake",
    alt: "A photo of Jake wearing a white shirt and black turtle neck.",
    url: "https://undergraduate.northeastern.edu/research/news/summer_2020_peak_awards/",
  },
  {
    id: 20,
    date: "May 20, 2020",
    title: "REU Award for Modeling SARS-CoV-2 Proteins",
    summary:
      "BioE Affiliated Faculty Mary Jo Ondrechen, Assistant Professor Mona Minkara, and COS Assistant Professor Steven Lopez were awarded a $73K NSF RAPID grant for 'Undergraduate Research in Modeling and Computation for Discovery of Molecular Probes for SARS-CoV-2 Proteins.'",
    img: "REUNews",
    alt: "A headshot photo of Mary and Mona. Both Mary and Mona are wearing white collared shirts",
    url: "https://coe.northeastern.edu/news/reu-award-for-modeling-sars-cov-2-proteins/",
  },
  {
    id: 21,
    date: "FEB 19, 2020",
    title: "Carmen Graham wins Spring 2020 PEAK Experiences Award",
    summary:
      "Congratulations! Carmen Graham on winning Spring 2020 PEAK Experiences Award",
    img: "carmenNews",
    alt: "Carmen wears a black shirt and glasses.",
    url: "https://undergraduate.northeastern.edu/research/news/announcing-the-spring-2020-peak-experiences-awardees/",
  },
  {
    id: 22,
    date: "FEB 19, 2020",
    title: "INCLUSIVITY FOR ALL: HOW TO MAKE YOUR RESEARCH GROUP ACCESSIBLE",
    summary:
      "I'm thrilled to have been featured in this AAAS Science article alongside Sara Lewthwaite, Jesse Shanahan, Meg O'Connell, and Amy-Charlotte Devitz written by Alaina Levine. This article highlights issues of accessibility in research and I hope that it helps propel the scientific community to be more inclusive to people with disabilities.",
    img: "AAAS",
    alt: "A red background with the text: 'Science AAAS' in white.",
    url: "https://www.science.org/content/article/inclusivity-all-how-%20make-your-research-group-accessible",
  },
  {
    id: 23,
    date: "AUG 29, 2019",
    title: "New Faculty Spotlight: Mona Minkara",
    summary:
      "Mona Minkara joins the Bioengineering department in August 2019 as an Assistant Professor. She earned her PhD at University of Florida Gainesville. Her research interests include theoretical physical chemistry; computational biochemistry and interfacial chemistry; biological physics; novel studies of surfactants using Monte Carlo algorithms.",
    img: "mona",
    alt: "A headshot of Mona wearing a purple and tan headscarf and a black blazer.",
    url: "https://coe.northeastern.edu/news/new-faculty-spotlight-mona-minkara/",
  },
];
