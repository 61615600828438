import { publicationsContent } from "./publicationsContent";
import { Nav, Row, Stack, Image, Col } from "../../bootstrap.js";
import { PDFImage } from "../PDF/PDFImage.js";
// import { PDFViewer } from "../PDF/PDFViewer.js";
import { PDFDescription } from "../PDF/PDFDescription.js";
import "./publications.css";
import { HeroImage2 } from "../HeroImage/HeroImage2.js";
import "../HeroImage/heroImage.css";
import "../PDF/pdf.css";
import { researchTitles } from "../PDF/pdfContent.js";
import { useMediaQuery } from "usehooks-ts";

export function Publications2() {
  const isMobile = useMediaQuery("(max-width: 576px)");

  return (
    <>
     
     
      <Row className="featuredPub gy-3" id="pub">
      <h2 className="redHeaderPub">Publications</h2>
      {/* <Image src="/images/Publication/publicationsBG.jpg" className="bgPic"
      alt=""></Image> */}
   
        {/* <Image className="bgPub" src="/images/Publication/publicationsBG.jpg"></Image> */}
     
         
      
        <Col lg={12} className="featuredPubRow">
     
          <Image
            src="/images/Publication/JcIM.jpg"
            className="img-fluid scImg"
            alt="Journal cover of a 3D chemical formula on a gray background."
          ></Image>
             <div className="heroFeatTextCont">
          <div className="heroFeatText">
            <h4 className="featuredJournal">Journal Of Chemical Information And Modeling</h4>
            {isMobile ? (
              <>
                <p className="journalVol">Vol 64 | Issue 20 | 2024</p>
              </>
            ) : (
              <>
                <p className="journalVol">Volume 64 | Issue 20 | 2024</p>
              </>
            )}

            <hr className="hrHeroFeat"></hr>
            <header className="featPDF">
              <a
                href={researchTitles[0].url}
                className="pdfLink"
                target="_blank"
                rel="noreferrer"
              >
                <h4 className="featTitle">{researchTitles[0].title}</h4>

                <h5 className="authors">{researchTitles[0].author}</h5>
                {/* <h5 className="h5Cit">{researchTitles[4].citation}</h5> */}
              </a>
            </header>
            <div className="pdfLinks">
              <a
                href={`/files/${researchTitles[0].pdf}.pdf`}
                download={researchTitles[0].title}
                className="downloadPdf"
              >
                <i
                  className="bi bi-file-earmark-arrow-down"
                  aria-label="Download pdf"
                ></i>
              </a>
            </div>
          </div>
          {/* <div>
              <p className="featuredWord">Featured</p>
            </div> */}
            </div>
        </Col>
        <Col lg={12} className="featuredPubRow firstF">
            <Image
              src="/images/Publication/compJournal.jpeg"
              className="img-flui scImg"
              alt="Journal cover in blues and black with a red and blue oscillating wave pattern across the center."
            ></Image>
            <div className="heroFeatTextCont">
            <div className="heroFeatText">
              <h4 className="featuredJournal">
                Computational and Structural Biotechnology Journal
              </h4>
              {isMobile ? (
                <>
                  <p className="journalVol">Vol 20 | 2022</p>
                </>
              ) : (
                <>
                  <p className="journalVol">Volume 20 | 2022</p>
                </>
              )}
              <hr className="hrHeroFeat"></hr>
              <header className="featPDF">
                <a
                  href={researchTitles[3].url}
                  className="pdfLink"
                  target="_blank"
                  rel="noreferrer"
                >
                  <h4 className="featTitle">{researchTitles[3].title}</h4>

                  <h5 className="authors">{researchTitles[3].author}</h5>
                  {/* <h5 className="h5Cit">{researchTitles[3].citation}</h5> */}
                </a>
              </header>
              <div className="pdfLinks">
                <a
                  href={`/files/${researchTitles[3].pdf}.pdf`}
                  download={researchTitles[3].title}
                  className="downloadPdf"
                >
                  <i
                    className="bi bi-file-earmark-arrow-down"
                    aria-label="Download pdf"
                  ></i>
                </a>
              </div>
            </div>
            {/* <div>
              <p className="featuredWord">Featured</p>
            </div> */}
            </div>
          </Col>
        </Row>
      
      {/* </section> */}
      <section className="publications">
        <PDFImage></PDFImage>
      </section>
    </>
  );
}
