import "./footer.css";
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';
import {
  Nav,
  Navbar,
  NavItem,
  Image,
  Col,
  Offcanvas,
  Row,
  Button,
} from "../../bootstrap.js";
import "../Contact/contact.css";
import { useMediaQuery } from "usehooks-ts";

export function Footer() {

  const isMobile = useMediaQuery("(max-width: 0px)");
  //put contact information and social media handles
  return <footer className="footer">
    <div className="navRowFooter">
      <Nav className="navFooter">
        <NavItem>
          <Link className="footerLink" to="/Home">Home</Link>
        </NavItem>
        <NavItem>
          <Link className="footerLink" to="/Research">Research</Link>
        </NavItem>
        <NavItem>
          <Link className="footerLink" to="/Publications">Publications</Link>
        </NavItem>
        <NavItem>
          <Link className="footerLink" to="/Publications">Group</Link>
        </NavItem>
        <NavItem>
          <Link className="footerLink" to="/Publications">News</Link>
        </NavItem>
        <NavItem>
          <Link className="footerLink" to="/Publications">Apply</Link>
        </NavItem>
        <NavItem>
          <Link className="footerLink" to="/Publications">Contact</Link>
        </NavItem>
      </Nav>
      <Image src="/images/logo/NLogoWhite.png" className="img-fluid NLogoWhite"></Image>


    </div>

    <hr className="hrLineFoot"></hr>
    <Row className="sec2Foot">
      {/* <div className="contactFoot"> */}
      <Col xs="auto" sm="auto" md="auto" lg="auto" xl="auto" xxl="auto">
        <h3 className="contactHeadersFoot">CONTACT</h3>

        <div className="email contactSecFoot">
          <a href="mailto:m.minkara@northeastern.edu">
            <p>m.minkara@northeastern.edu</p>
          </a>
        </div>
        <p>617-373-2031</p>
      </Col>

      <Col className="contactSecFoot" xs="auto" sm="auto" md="auto" lg="auto" xl="auto" xxl="auto">
        <h3 className="contactHeadersFoot">OFFICE</h3>
        <a href="https://www.google.com/maps/place/Northeastern+University+Interdisciplinary+Science+and+Engineering+Complex/@42.3377192,-71.0895366,17z/data=!3m1!4b1!4m6!3m5!1s0x89e37a22bfa1d9d7:0xbab99b179dfdea31!8m2!3d42.3377153!4d-71.0869617!16s%2Fg%2F11f_ds1m54?entry=ttu">
          <p>ISEC 325</p>
        </a>

        <a href="https://bioe.northeastern.edu/">
          <p>Department of Bioengineering</p>
        </a>
      </Col >
      <Col className="contactSecFoot" xs="auto" sm="auto" md="auto" lg="auto" xl="auto" xxl="auto">
        <h3 className="contactHeadersFoot">RELATED LINKS</h3>
        <a href="https://coe.northeastern.edu/people/minkara-mona/#_ga=2.38495139.1283576135.1610572978-967137612.1582053801">
          <p>Dr. Mona Minkara</p>
        </a>
        <a href="https://bioe.northeastern.edu/">
          <p>Faculty and Staff Directory</p>
        </a>
        <a href="https://monaminkara.com">
          <p>www.monaminkara.com</p>
        </a>
      </Col>
      <Col className="contactSecFoot" xs="auto" sm="auto" md="auto" lg="auto" xl="auto" xxl="auto">
        <h3 className="contactHeadersFoot">SOCIAL MEDIA</h3>
        <div className="contactSoc">
          <a href="https://www.linkedin.com/in/monaminkara/">
            <i class="bi bi-linkedin"></i>
          </a>
          <a href="https://www.youtube.com/channel/UCYBXlV5HoElWXs5zCKNEtaQ">
            <i class="bi bi-youtube"></i>
          </a>
        </div>
      </Col>

    </Row>
    <div className="row3Footer">
      <Row className="copyrightRow">
        {/* <Col className="copyCol" xs="auto" sm="auto" md="auto" lg="auto" xl="auto" xxl="auto">
          <p className="copyright">Accessibility</p>

        </Col> */}
        {/* <Col className="copyCol" xs="auto" sm="auto" md="auto" lg="auto" xl="auto" xxl="auto"> <p className="copyright"> | </p></Col> */}
        <Col className="copyCol" xs="auto" sm="auto" md="auto" lg="auto" xl="auto" xxl="auto">
          <a href="/PrivacyPolicy" className="copyright"> Privacy Policy</a>
        </Col>
        {/* <Col className="copyCol" xs="auto" sm="auto" md="auto" lg="auto" xl="auto" xxl="auto"> <p className="copyright"> | </p></Col> */}

        {/* <Col className="copyCol" xs="auto" sm="auto" md="auto" lg="auto" xl="auto" xxl="auto">
          <p className="copyright"> Terms of Service</p>
        </Col> */}
        {/* <Col className="copyCol" xs="auto" sm="auto" md="auto" lg="auto" xl="auto" xxl="auto"> <p className="copyright"> | </p></Col> */}
        {/* <Col className="copyCol" xs="auto" sm="auto" md="auto" lg="auto" xl="auto" xxl="auto">
          <p className="copyright"> © 2024 Minkara <span className="combineFooter">COMBINE</span> Lab</p>
        </Col> */}
      </Row>

      {isMobile ? (<></>) : (<div className="footerLogo"><Image src="/images/logo/logoWhite2.svg" className="img-fluid whiteLogo"></Image></div>
      )}


    </div>
    {isMobile ? (<Image src="/images/logo/logoWhite2.svg" className="img-fluid whiteLogo"
    alt="Minkara Combine Lab logo in white: the name and a magnifying glass over some proteins to the right."></Image>) : (<></>)}

  </footer>;
}


