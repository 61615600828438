import { Button } from "../../bootstrap.js";
import "./researchButton.css";

export function ResearchButton() {
  return (
  
    <Button href="/Publications" className="researchButton button3D">
      Latest Research
    </Button>
   
  );
}

