import {
  Row,
  Col,
  Card,
  Image,
  OverlayTrigger,
  Tooltip,
  Button,
} from "../../bootstrap.js";
import "./toolTip.css";

export function TooltipComponent({ placement, content1, content2 }) {
  return (
    <>
      <OverlayTrigger
        key={placement}
        placement={placement}
        overlay={
          <Tooltip id={`tooltip-${placement}`} className="toolTip">
            {content2}
          </Tooltip>
        }
        className="toolTip"
      >
        <p>{content1}</p>
      </OverlayTrigger>
    </>
  );
}
