import { Row, Col, Image, Carousel } from "../../bootstrap.js";
import "./methods.css";
import "./Projects/projects.css";
import "./research.css";
import { PDFViewer } from "../PDF/PDFViewer.js";
import { TooltipComponent } from "../Tooltip/TooltipComponent.js";
import { useMediaQuery } from "usehooks-ts";

export function Methods2({ setIndex }) {
  const isMobile = useMediaQuery("(max-width: 992px)");

  return (
    <>
       {isMobile ? (
        /* Mobile Version */
        <>
          <h3 className="redSmHeaderMethods">METHODS</h3>
          <Row className="gy-3 methodsRow">
            <Col lg={12} className="methodsCol">
              <div className="researchSideBar">
                <h4 className="blackHeaderResearch">Molecular Dynamics</h4>
              </div>

              <Col xs={12} sm={12} md={6} lg={6} className="methodBox1">
                <TooltipComponent
                  placement="top"
                  content1={
                    <div className="method2Imgs">
                      <Col xs={12} sm={12} md={5} lg={8}>
                        <video width="320" height="240" controls>
                          <source src="/videos/DM_SPD.mp4" type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                        <p className="captionsPics">DM SP-D</p>
                      </Col>
                    </div>
                  }
                  content2={
                    <>
                      <h5>Applications:</h5>
                      <p>
                        (1) Understanding molecular interactions; (2)
                        Understanding time dependent molecular phenomena; (3)
                        Probing environmental influences on changes in
                        molecular conformation
                      </p>
                    </>
                  }
                ></TooltipComponent>
              </Col>

              <Col md={6} className="carContM" id="pdfViewer1">
                <Carousel id="carousel" className="carouselProjects carousel-dark">
                  <Carousel.Item>
                    <PDFViewer
                      title="Elucidating the Enhanced Binding Affinity of a Double Mutant SP-D With Trimannose on the Influenza A Virus Using Molecular Dynamics"
                      file="15_elucidating"
                      styling="researchPDF2"
                    ></PDFViewer>
                    <a href="/Publications">
                      <div className="carCaptions">
                        <p className="captionsPicsCar1">
                          Elucidating the Enhanced Binding Affinity of a Double Mutant SP-D With
                          Trimannose on the Influenza A Virus Using Molecular Dynamics
                        </p>
                        <p className="captionsPicsCar">Li, D.; Minkara, M.S.</p>
                        <p className="captionsPicsCar">Computational and Structural Biotechnology Journal, 2022</p>
                      </div>
                    </a>
                  </Carousel.Item>
                </Carousel>
              </Col>
            </Col>
          </Row>
          <Row className="gy-3 methodsRow">
            <Col lg={12} className="methodsCol">
            <div className="researchSideBar">
              <h4 className="blackHeaderMethods">Monte Carlo</h4>
            </div>  
              <Col xs={12} sm={12} md={6} lg={6} className="methodBox1">
                <TooltipComponent
                  placement="top"
                  content1={
                    <div className="method2Imgs">
                      <Col xs={12} sm={12} md={5} lg={8}>
                        <Image
                          src="/images/Research/pi.jpeg"
                          className="img-fluid piImg"
                          alt="A diagram of pi calculations."
                        ></Image>
                        <p className="captionsPics">Monte Carlo Simulation of Pi</p>
                      </Col>
                    </div>
                  }
                  content2={
                    <>
                      <h5>Applications:</h5>
                      <p>
                        (1) Understanding phase transitions: vapor-liquid equilibrium (VLE) or
                        liquid-liquid equilibrium (LLE); (2) Understanding all possible structural
                        conformations of molecules
                      </p>
                    </>
                  }
                ></TooltipComponent>
              </Col>

              <Col md={6} className="carContM" id="pdfViewer1">
                <Carousel id="carousel" className="carouselProjects carousel-dark">
                  <Carousel.Item>
                    <PDFViewer
                      title="Nonane and Hexanol Adsorption in the Lamellar Phase of a Nonionic Surfactant: Molecular Simulations and Comparison to Ideal Adsorbed Solution Theory"
                      file="13_nonaneAndHexanol"
                      styling="researchPDF2"
                    ></PDFViewer>
                    <a href="/Publications">
                      <div className="carCaptions">
                        <p className="captionsPicsCar1">
                          Nonane and Hexanol Adsorption in the Lamellar Phase of a Nonionic
                          Surfactant: Molecular Simulations and Comparison to Ideal Adsorbed Solution
                          Theory
                        </p>
                        <p className="captionsPicsCar">Minkara, M.S.; Josephson, T.R.; Venteicher, C.L.; et al.</p>
                        <p className="captionsPicsCar">Journal of Chemical Physics, 2022</p>
                      </div>
                    </a>
                  </Carousel.Item>
                </Carousel>
              </Col>
            </Col>
          </Row>
          <Row className="gy-3 methodsRow">
        <Col lg={12} className="methodsCol">
          <div className="researchSideBar">
            <h4 className="blackHeaderResearch">Molecular Docking</h4>
          </div>

          <Col xs={12} sm={12} md={6} lg={6} className="methodBox4">
            <TooltipComponent
              position="top"
              content1={
                <Image
                  src="/images/Research/ligand.png"
                  className="img-fluid ligand"
                  alt="A diagram of a docking scheme"
                />
              }
              content2={
                <>
                  <p>Helps to find inhibitors for possible targets. </p>
                  <p>
                    Docking scheme for hit-based screening of natural products and their potential targets.
                  </p>
                </>
              }
            />
            <p className="captionsPics">2D Ligand Binding Interaction Map</p>
          </Col>

          <Col md={6} className="carContM" id="pdfViewer1">
            <Carousel id="carousel" className="carouselProjects carousel-dark">
              <Carousel.Item>
                <PDFViewer
                  title="Reduction of Urease Activity by Interaction with the Flap Covering the Active Site"
                  file="19_ReductionofUrease"
                  styling="researchPDF2"
                />
                <a href="/Publications">
                  <div className="carCaptions">
                    <p className="captionsPicsCar1">
                      Reduction of Urease Activity by Interaction With the Flap Covering Active Site
                    </p>
                    <p className="captionsPicsCar">Macomber, L.; Minkara, M. S.; Hausinger, R.P.; et al.</p>
                    <p className="captionsPicsCar">Journal of Chemical Information and Modeling, 2015</p>
                  </div>
                </a>
              </Carousel.Item>
            </Carousel>
          </Col>
        </Col>
      </Row>

      {/* Homology Modeling Section */}
      <Row className="gy-3 methodsRow">
        <Col lg={12} className="methodsCol">
          <div className="researchSideBar">
            <h4 className="blackHeaderResearch">Homology Modeling</h4>
          </div>

          <Col xs={12} sm={12} md={5} lg={8} className="methodBox3">
            <TooltipComponent
              content1={
                <Image
                  src="/images/Research/homology.png"
                  className="img-fluid"
                  alt="A diagram depicting the addition of amino acid plus fragment structure plus related structure is equal to Predicted Model multiplied by 10,000."
                />
              }
              content2={
                <>
                  <h5>Applications:</h5>
                  <p>Predict the structure of proteins that are difficult to crystalize.</p>
                  <p>Homology model scheme for structural predictions of SP-B.</p>
                </>
              }
            />
            <p className="captionsPics">
              Using known amino acid sequences and fragment structures to predict related protein structures.
            </p>
          </Col>
        </Col>
      </Row>
          
          
        </>
      ) : (
        /* Desktop Version */
        <>
          <div className="researchSideBar">
            <h3 className="redSmHeaderResearch">METHODS</h3>
          </div>

          <Row className="gy-3 methodsRow">
            <Col lg={12} className="methodsCol">
              <div className="researchSideBar">
                <h4 className="blackHeaderResearch">Molecular Dynamics</h4>
              </div>

              <Col xs={12} sm={12} md={6} lg={6} className="methodBox1">
                <TooltipComponent
                  placement="top"
                  content1={
                    <div className="method2Imgs">
                      <Col xs={12} sm={12} md={5} lg={8}>
                        <video width="320" height="240" controls>
                          <source src="/videos/DM_SPD.mp4" type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                        <p className="captionsPics">DM SP-D</p>
                      </Col>
                    </div>
                  }
                  content2={
                    <>
                      <h5>Applications:</h5>
                      <p>
                        (1) Understanding molecular interactions; (2) Understanding time dependent
                        molecular phenomena; (3) Probing environmental influences on changes in
                        molecular conformation
                      </p>
                    </>
                  }
                ></TooltipComponent>
              </Col>

              <Col md={6} className="carContM" id="pdfViewer1">
                <Carousel id="carousel" className="carouselProjects carousel-dark">
                  <Carousel.Item>
                    <PDFViewer
                      title="Elucidating the Enhanced Binding Affinity of a Double Mutant SP-D With Trimannose on the Influenza A Virus Using Molecular Dynamics"
                      file="15_elucidating"
                      styling="researchPDF2"
                    ></PDFViewer>
                    <a href="/Publications">
                      <div className="carCaptions">
                        <p className="captionsPicsCar1">
                          Elucidating the Enhanced Binding Affinity of a Double Mutant SP-D With
                          Trimannose on the Influenza A Virus Using Molecular Dynamics
                        </p>
                        <p className="captionsPicsCar">Li, D.; Minkara, M.S.</p>
                        <p className="captionsPicsCar">Computational and Structural Biotechnology Journal, 2022</p>
                      </div>
                    </a>
                  </Carousel.Item>
                </Carousel>
              </Col>
            </Col>
          </Row>

          <Row className="gy-3 methodsRow">
            <Col lg={12} className="methodsCol">
              <div className="researchSideBar">
                <h4 className="blackHeaderResearch">Monte Carlo</h4>
              </div>

              <Col xs={12} sm={12} md={6} lg={6} className="methodBox1">
                <TooltipComponent
                  placement="top"
                  content1={
                    <div className="method2Imgs">
                      <Col xs={12} sm={12} md={5} lg={8}>
                        <Image
                          src="/images/Research/pi.jpeg"
                          className="img-fluid piImg"
                          alt="A diagram of pi calculations."
                        ></Image>
                        <p className="captionsPics">Monte Carlo Simulation of Pi</p>
                      </Col>
                    </div>
                  }
                  content2={
                    <>
                      <h5>Applications:</h5>
                      <p>
                        (1) Understanding phase transitions: vapor-liquid equilibrium (VLE) or
                        liquid-liquid equilibrium (LLE); (2) Understanding all possible structural
                        conformations of molecules
                      </p>
                    </>
                  }
                ></TooltipComponent>
              </Col>

              <Col md={6} className="carContM" id="pdfViewer1">
                <Carousel id="carousel" className="carouselProjects carousel-dark">
                  <Carousel.Item>
                    <PDFViewer
                      title="Nonane and Hexanol Adsorption in the Lamellar Phase of a Nonionic Surfactant: Molecular Simulations and Comparison to Ideal Adsorbed Solution Theory"
                      file="13_nonaneAndHexanol"
                      styling="researchPDF2"
                    ></PDFViewer>
                    <a href="/Publications">
                      <div className="carCaptions">
                        <p className="captionsPicsCar1">
                          Nonane and Hexanol Adsorption in the Lamellar Phase of a Nonionic
                          Surfactant: Molecular Simulations and Comparison to Ideal Adsorbed Solution
                          Theory
                        </p>
                        <p className="captionsPicsCar">Minkara, M.S.; Josephson, T.R.; Venteicher, C.L.; et al.</p>
                        <p className="captionsPicsCar">Journal of Chemical Physics, 2022</p>
                      </div>
                    </a>
                  </Carousel.Item>
                </Carousel>
              </Col>
            </Col>
          </Row>
          
          <Row className="gy-3 methodsRow">
        <Col lg={12} className="methodsCol">
          <div className="researchSideBar">
            <h4 className="blackHeaderResearch">Molecular Docking</h4>
          </div>

          <Col xs={12} sm={12} md={6} lg={6} className="methodBox4">
            <TooltipComponent
              position="top"
              content1={
                <Image
                  src="/images/Research/ligand.png"
                  className="img-fluid ligand"
                  alt="A diagram of a docking scheme"
                />
              }
              content2={
                <>
                  <p>Helps to find inhibitors for possible targets. </p>
                  <p>
                    Docking scheme for hit-based screening of natural products and their potential targets.
                  </p>
                </>
              }
            />
            <p className="captionsPics">2D Ligand Binding Interaction Map</p>
          </Col>

          <Col md={6} className="carContM" id="pdfViewer1">
            <Carousel id="carousel" className="carouselProjects carousel-dark">
              <Carousel.Item>
                <PDFViewer
                  title="Reduction of Urease Activity by Interaction with the Flap Covering the Active Site"
                  file="19_ReductionofUrease"
                  styling="researchPDF2"
                />
                <a href="/Publications">
                  <div className="carCaptions">
                    <p className="captionsPicsCar1">
                      Reduction of Urease Activity by Interaction With the Flap Covering Active Site
                    </p>
                    <p className="captionsPicsCar">Macomber, L.; Minkara, M. S.; Hausinger, R.P.; et al.</p>
                    <p className="captionsPicsCar">Journal of Chemical Information and Modeling, 2015</p>
                  </div>
                </a>
              </Carousel.Item>
            </Carousel>
          </Col>
        </Col>
      </Row>

      {/* Homology Modeling Section */}
      <Row className="gy-3 methodsRow">
        <Col lg={12} className="methodsCol">
          <div className="researchSideBar">
            <h4 className="blackHeaderResearch">Homology Modeling</h4>
          </div>

          <Col xs={12} sm={12} md={5} lg={8} className="methodBox3">
            <TooltipComponent
              content1={
                <Image
                  src="/images/Research/homology.png"
                  className="img-fluid"
                  alt="A diagram depicting the addition of amino acid plus fragment structure plus related structure is equal to Predicted Model multiplied by 10,000."
                />
              }
              content2={
                <>
                  <h5>Applications:</h5>
                  <p>Predict the structure of proteins that are difficult to crystalize.</p>
                  <p>Homology model scheme for structural predictions of SP-B.</p>
                </>
              }
            />
            <p className="captionsPics">
              Using known amino acid sequences and fragment structures to predict related protein structures.
            </p>
          </Col>
        </Col>
      </Row>
        </>
      )}
    </>
  );
}
