
import "./groupMembers.css";
import { Row, Col, Image } from "../../bootstrap.js";
import { useMediaQuery } from "usehooks-ts";

export function GroupMembers({ members }) {
  const isMobile = useMediaQuery("(max-width: 992px)");

  return (
    <section>
      <Row className="group2Row  gy-5">
        {members.map((member, i) =>
          i > 0 ? (
            isMobile ? (
              /* Mobile Version */
              <div key={i} className="groupContainer">
                <Col xs={12} sm={12} className="groupPic">
                  <Image
                    src={`/images/Group/${member.img}.jpg`}
                    className="img-fluid memberPic"
                    alt={member.alt}
                  />
                </Col>
                <Col s={12} sm={12} md={12} lg={12} >
                
                  <header>
                    <h4 className="groupName">{member.name}</h4>
                  </header>
                  <div className="positionsGroup">
                    <h5 className="groupTitle">{member.title}</h5>
                    <div className="aboveBorder">
                      <h5 className={member.major ? "groupTitle2" : "education"}>
                        {member.major ? member.major + " Major" : member.education}
                      </h5>
                      <div className="groupSoc">
                      <div className="groupSocCont">
                        <a href={member.linkedIn}>
                          <i className="bi bi-linkedin"></i>
                        </a>
                      </div>
                      </div>
                    </div>
                    <hr className="hrLine" />
                    <p className="funFact">{member.bio}</p>
                    {member.awards && member.awards.trim() !== "" && (
                      <>
                        <p className="redHeader">Awards</p>
                        <ul className="awards">
                          {member.awards.split('•').map((award, index) =>
                            award.trim() && <li key={index}>{award.trim()}</li>
                          )}
                        </ul>
                      </>
                    )}
                  </div>
                </Col>
              </div>
            ) : (
              /* Desktop Version */
              
              <div className="groupRowCont">
              <Col xs={12} sm={12} className="groupPic">
                <Image
                  src={`/images/Group/${member.img}.jpg`}
                  className="img-fluid memberPic floatImg"
                  alt={`${member.alt}`}
                ></Image>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12}>
                <div className="bioText">
                  
                    <h4 className="groupName">{member.name}</h4>
                  
                  <div className="positionsGroup">
                   <h5 className="groupTitle">
                       {member.title}
                      </h5>

                    <div className="aboveBorder">
                      <h5 className={member.major ? "groupTitle2" : "education"}>
                        {member.major ? member.major + " Major" : member.education}
                      </h5>

                      <div className="groupSoc">
                        <div className="groupSocCont">
                        <a href={member.linkedIn}>
                            <i class="bi bi-linkedin"></i>
                          </a>
                         
                          </div>
                        </div>
                    </div>
                    <hr className="hrLine"></hr>
                    <div className="researchGroup">
                 
                      {member.program && <p className="programGroup">Program: <span className="programText">{member.program}</span></p>}
                      {/* <p className="researchText">{member.program}</p> */}
               
                    </div>
                    <p className="funFact">{member.bio}</p>
                    {member.awards && member.awards.trim() !== "" && (
  <>
    <p className="redHeader">Awards</p>
    <ul className="awards">
      {member.awards.split('•').map((award, index) => 
        award.trim() && <li key={index}>{award.trim()}</li>
      )}
    </ul>
  </>
)}
                  </div>
                  </div>
                </Col>
              </div>
            )
          ) : null
        )}
      </Row>
    </section>
  );
}

