import "./App.css";

import "bootstrap/dist/css/bootstrap.min.css";

import 'bootstrap/dist/js/bootstrap.bundle.min.js';


import "./App.css";

import 'typeface-work-sans'; /* Regular font */
// import 'typeface-work-sans-extralight'; 
// import 'typeface-work-sans-semibold'; 

import { NUHeader } from "./Components/NUHeader/NUHeader.js";
import { Footer } from "./Components/Footer/Footer.js";
import { NavBar } from "./Components/NavBar/NavBar";
import { Routing } from "./Routing.js";
import React, { useState, useEffect } from "react";

export function App() {


  return (
    <body>
      <main>
      <header className="app">
        <NUHeader></NUHeader>

        <NavBar></NavBar>
      </header>
      <section className="app">
        <Routing></Routing>
      </section>
      </main>
      <footer className="app">
        <Footer></Footer>
      </footer>
    </body>
  );
}
