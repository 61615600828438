export const researchTitles = [
  {
    title:
    "Comparative Assessment of Water Models in Protein−Glycan Interaction: Insights from Alchemical Free Energy Calculations and  Molecular Dynamics Simulations",
    author: "Deng Li and Mona S. Minkara",
    citation: "Journal of Chemical Information and Modeling, 2024",
    img: "pub24",
    pdf: "19_JCIM",
    url: "https://pubs.acs.org/doi/10.1021/acs.jcim.4c01361",

  }
  ,
  {
    title:
      "Universal Pictures: A Lithophane Codex Helps Teenagers With Blindness Visualize Nanoscopic Systems",
    author: "Alonzo, E.A.; Lato, T.J.; ... Minkara, M.; et al.",
    citation: "Science Advances, 2024",
    img: "pub18",
    pdf: "18_universalPictures",
    url: "https://www.science.org/doi/10.1126/sciadv.adj8099",
  },
  
  {
    title:
      "AstroAccess: Testing Accessibility Accommodations for Disabled and Mixed-Ability Crews Operating in Space-Like Environments",
    author: "Molaro, J.L.; Kapusta, A.; ... Minkara, M.; et al.",
    citation: "Acta Astronautica, 2024",
    img: "pub17",
    pdf: "17_AstroAccess",
    url: "https://www.sciencedirect.com/science/article/pii/S0094576524000699?via%3Dihub",
  },
  {
    title:
      "Virtually the Same? Evaluating the Effectiveness of Remote Undergraduate Research Experiences",
    author: "Minkara, M.S.; Erickson, O.A.; Cole, R.B.; et al.",
    citation: "CBE—Life Sciences Education, 2023",
    img: "pub13",
    pdf: "16_virtuallyTheSame",
    url: "https://www.lifescied.org/doi/full/10.1187/cbe.22-01-0001",
  },
  {
    title:
      "Elucidating the Enhanced Binding Affinity of a Double Mutant SP-D With Trimannose on the Influenza A Virus Using Molecular Dynamics",
    author: "Li, D.; Minkara, M.S.",
    citation: "Computational and Structural Biotechnology Journal, 2022",
    img: "pub16",
    pdf: "15_elucidating",
    star: "star",
    url: "https://www.sciencedirect.com/science/article/pii/S2001037022003798",
  },
  {
    title:
      "Data for All: Tactile Graphics That Light up With Picture-Perfect Resolution",
    author: "Minkara, M.; Koone, J.C.; Dashnaw, C.M.; et al.",
    citation: "Science Advances, 2022",
    img: "pub15",
    pdf: "14_data",
    star: "star",
    url: "https://www.science.org/doi/full/10.1126/sciadv.abq2640",
  },
  {
    title:
      "Nonane and Hexanol Adsorption in the Lamellar Phase of a Nonionic Surfactant: Molecular Simulations and Comparison to Ideal Adsorbed Solution Theory",
    author: "Minkara, M.S.; Josephson, T.R.; Venteicher, C.L.; et al.",
    citation: "Journal of Chemical Physics, 2022",
    img: "pub14",
    pdf: "13_nonaneAndHexanol",
    url: "https://pubs.acs.org/doi/abs/10.1021/acs.jpcb.2c02871",
  },
  {
    title:
      "'How Do We Do This at a Distance?!' A Descriptive study of Remote Undergraduate Research Programs During COVID-19",
    author: "Erickson, O.A.; Cole, R.B.; Isaacs J.M.; et al.",
    citation: "CBE—Life Sciences Education, 2022",
    img: "pub12",
    pdf: "12_howDoWe",
    url: "https://www.lifescied.org/doi/full/10.1187/cbe.21-05-0125",
  },
  {
    title: "The Influence of a Blind Professor in a Bioengineering Course",
    author: "Greenvall, B.R.; Tiano, A.L.; ... Minkara, M.; et al.",
    citation: "Biomedical Engineering Education, 2021",
    img: "pub11",
    pdf: "11_influence",
    url: "https://link.springer.com/article/10.1007/s43683-021-00052-1",
  },
  {
    title:
      "Partial Molar Properties From Molecular Simulation Using Multiple Linear Regression",
    author: "Josephson, T. R.; Singh, R.; Minkara, M. S.; et al.",
    citation: "Molecular Physics, 2019",
    img: "pub10",
    pdf: "10_partial",
    url: "https://www.tandfonline.com/doi/full/10.1080/00268976.2019.1648898",
  },
  {
    title:
      "A New Equation of State for Homo-Polymers in Dissipative Particle Dynamics",
    author: "Minkara, M. S.; Hembree, R. H.; Jamadagni, S. N.; et al.",
    citation: "Journal of Chemical Physics, 2019",
    img: "pub9",
    pdf: "9_new",
    url: "https://pubs.aip.org/aip/jcp/article/150/12/124104/595345/A-new-equation-of-state-for-homo-polymers-in",
  },
  {
    title:
      "Probing Additive Loading in the Lamellar Phase of a Nonionic Surfactant: Gibbs Ensemble Monte Carlo Simulations Using the SDK Force Field",
    author: "Minkara, M. S.; Lindsey, R. K.; Hembree, R. H.; et al.",
    citation: "Langmuir, 2018",
    img: "pub8",
    pdf: "8_probing",
    url: "https://pubs.acs.org/doi/10.1021/acs.langmuir.8b00687",
  },
  {
    title:
      "Monte Carlo Simulations Probing the Liquid/Vapour Interface of Water/Hexane Mixtures: Adsorption Thermodynamics, Hydrophobic Effect, and Structural Analysis",
    author: "Minkara, M. S.; Josephson, T.; Venteicher, C. L.; et al.",
    citation: "Molecular Physics, 2018",
    img: "pub7",
    pdf: "7_monte",
    url: "https://www.tandfonline.com/doi/full/10.1080/00268976.2018.1471233",
  },
  {
    title:
      "Effect of 10.5 M Aqueous Urea on Helicobacter Pylori Urease: A Molecular Dynamics Study",
    author: "Minkara, M. S.; Weaver, M. N.; Merz, K. M. Jr.",
    citation: "Biochemistry, 2015",
    img: "pub6",
    pdf: "6_urea",
    url: "https://pubs.acs.org/doi/10.1021/acs.biochem.5b00078",
  },
  {
    title:
      "Implementation of Protocols to Enable Doctoral Training in Physical and Computational Chemistry of a Blind Graduate Student",
    author: "Minkara, M. S.; Weaver, M. N.; Gorske, J.; et al.",
    citation: "Journal of Chemical Education, 2015",
    img: "pub5",
    pdf: "5_implement",
    url: "https://pubs.acs.org/doi/10.1021/ed5009552",
  },
  {
    title:
      "Reduction of Urease Activity by Interaction With the Flap Covering Active Site",
    author: "Macomber, L.; Minkara, M. S.; Hausinger, R.P.; et al.",
    citation: "Journal of Chemical Information and Modeling, 2015",
    img: "pub4",
    pdf: "4_reduction",
    url: "https://pubs.acs.org/doi/10.1021/ci500562t",
  },
  {
    title: "Molecular Dynamics Study of Helicobacter Pylori Urease",
    author: "Minkara, M. S.; Ucisik, M. N.; Weaver, M. N.; et al.",
    citation: "Journal of Chemical Theory and Computation, 2014",
    img: "pub3",
    pdf: "3_molecular",
    url: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4020587/",
  },
  {
    title:
      "Analysis of Fast Boundary-Integral Approximations for Modeling Electrostatic Contributions of Molecular Binding",
    author: "Kreienkamp, A. B.; Liu, L. Y.; Minkara, M. S.; et al.",
    citation: "Molecular Based Mathematical Biology, 2013",
    img: "pub2",
    pdf: "2_analysis",
    url: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3897805/",
  },
  {
    title:
      "Multiple Drugs and Multiple Targets: An Analysis of the Electrostatic Determinants of Binding Between Non-Nucleoside HIV-1 Reverse Transcriptase Inhibitors and Variants of HIV-1 RT",
    author: "Minkara, M. S.; Davis, P. H.; Radhakrishnan M. L.",
    citation: "Proteins: Structure, Function, and Bioinformatics, 2012",
    img: "pub1",
    pdf: "1_multiple",
    url: "https://pubmed.ncbi.nlm.nih.gov/22095671/",
  },
  // {
  //   title:
  //     "Analyzing Electrostatic Determinants of Affinity and Promiscuity in the HIV-1 Reverse Transcriptase System Using Charge Optimization",
  //   author: "Minkara, M. S.; Radhakrishnan M. L.",
  //   citation: "Proteins, 2009",
  //   img: "pub0",
  //   pdf: "0_analyzingElectrostatic",
  //   url: "https://www.cell.com/biophysj/pdf/S0006-3495(08)03351-1.pdf",
  // },
];
