import { Row, Col, Card, Image } from "../../bootstrap.js";
import { newsContent } from "./newsContent.js";
import "./news.css";
import { HeroImage } from "../HeroImage/HeroImage.js";
import { useMediaQuery } from "usehooks-ts";

export function News({ news = newsContent }) {
  const isMobile = useMediaQuery("(max-width: 992px)");
  const featured= {
    id: 1,
    date: "MAR 12, 2024",
    title:
      "Minkara Receives NSF CAREER Award to Improve Immune Response with AI",
    summary:
      "BioE Assistant Professor Mona Minkara was awarded a $827,000 NSF CAREER award for 'Decoding the Code of Glycan-Collectin Interactions: Computational Engineering of Surfactant Proteins for Tailored Glycan Recognition.'",
    img: "mona",
    alt: "A headshot of Mona wearing a purple and tan headscarf and a black blazer.",
    url: "https://coe.northeastern.edu/news/minkara-receives-nsf-career-award-to-improve-immune-response-with-ai/",
  }

  return (
    <>
    <div className="newsBG">
     <header>
          <h3 className="newsHeader">News</h3>
        </header>
    <Row className="featuredNewsRow">
   <Col xs={9} sm={7} md={6} lg={4} xl={3} xxl={3}>
    <Image src="/images/Group/NSF2.jpg"
    className="img-fluid nsfCareer"
    alt="NSF career award logo"></Image>
    </Col>
    
   <Col xs={12} sm={12} md={6} lg={8} xl={9} xxl={9}>
              <a
                href={featured.url ? `${featured.url}` : undefined}
                className="newsLink"
              >
                
                <header>
                  <h3 className="featuredNewsTitle">{featured.title}</h3>
                  <p className="featuredNewsDate">{featured.date}, <span className="featuredNews">Featured</span></p>
                </header>
                <p className="summary">{featured.summary}</p>
              </a>
            </Col>
           
            </Row>
            </div>
      {/* <HeroImage title="News" imgUrl="News/newsBG.jpg"></HeroImage> */}

      <section className="news">
        {news.map((item, i) => (
          <Row className={i === 0 ? "newsRow0" : "newsRow"}>
            <Col className="m-0 p-0 newsCol">
              <a
                href={item.url ? `${item.url}` : undefined}
                className="newsLink"
              >
                <p className="newsDate">{item.date}</p>
                <header>
                  <h3 className="newsTitle">{item.title}</h3>
                </header>
                <p className="summary">{item.summary}</p>
              </a>
            </Col>
            <div className="imgCont">
              <div className={item.img === "NSF2" ? " nsfCareerCont " : ""} >
                <a href={item.url ? `${item.url}` : undefined}>
                 
                  <Image
                    src={`/images/Group/${item.img}.jpg`}
                    className= {item.img === "NSF2" ? " nsfCareer2 " : "newsImg"} 
                    alt={`${item.alt}`}
                  ></Image>
                </a>
              </div>
            </div>
          </Row>
        ))}
      </section>
    </>
  );
}
