import { Row, Col, Image } from "../../bootstrap.js";
import "./programs.css";
import { useMediaQuery } from "usehooks-ts";

export function Programs() {
  const isMobile2 = useMediaQuery("(max-width: 1490px)");

  const programsHome = [
    {
      title: "Immunoproteins",
      alt: "Immunoproteins that appear as a cluster of tight-knit 3-D spiral curls in purple, yellow and teal.",
    },
    {
      title: "Alveolar Interface",
      alt: "A zoomed in photo of alveolar interface in red.",
    },
    {
      title: "Drug Delivery",
      alt: "A diagram on how DPPC lipid can serve as a drug delivery method.",
    },
    {
      title: "Computational Methods",
      alt: "A force field of surfactant proteins.",
    },
    {
      title: "Multisensory Tools",
      alt: "Human fingers trace the surface of a lithophane of a protein.",
    },
  ];

  return (
    <>
      {isMobile2 ? (
        // Mobile version content
        <div className="programsCont3D">
          <Row className="programRow1">
            <h3 className="redHeaderHome">Research Programs</h3>
            <p className="img_des1">
              We model pulmonary surfactant (PS) at the molecular level using
              various computational techniques to understand the different
              components of pulmonary surfactant to advance human health.
            </p>
            <p className="learnProject">
              Tap to learn more about each ongoing program.
            </p>

            {programsHome.map((program, i) => (
              <Col
                
                className="programCont"
                key={i}
              >
                <a href="/Research" className="linkImage">
                  <div className="mag">
                    <Image
                      src={`/images/HomePics/program${i + 1}Home.png`}
                      alt={`${program.alt}`}
                      className={
                        i === 0
                          ? "ImageProgram img-fluid circle fix"
                          : "ImageProgram img-fluid circle"
                      }
                    />
                  </div>
                  <p className="programHome">
                    <i
                      className={`bi bi-${i + 1}-square number`}
                      aria-label={`Program ${i + 1}`}
                    ></i>
                    {`${program.title}`}
                  </p>
                </a>
              </Col>
            ))}
            <div className="bottomPrograms"></div>
          </Row>

          <Row className="justify-content-center programRow">
            <Col xs="auto" className="d-flex justify-content-center">
              <Image
                src={`/images/HomePics/program_pulmo.png`}
                alt="Surfactant System"
                className="img-fluid bigimage"
              />
            </Col>
            <p className="img_des">Pulmonary Surfactant System</p>
            {/* <p className="obj">
              At the COMBINE Lab, we believe that real innovation comes from
              seeing the world differently and breaking down barriers to
              knowledge. Our approach goes beyond traditional perspectives,
              embracing a future where everyone can contribute to advancing
              science, regardless of physical limitations.
            </p> */}
          </Row>

          {/* <section className="desc-sec">
            <Row className="obj-desc">• Innovation</Row>
            <p className="desc">
              We push the boundaries of what’s possible, using computational
              techniques to decode the complexities of biological interfaces and
              contribute to the improvement of human health.
            </p>

            <Row className="obj-desc">• Inclusivity</Row>
            <p className="desc">
              We are committed to making STEM accessible to all, whether through
              our multisensory tools for blind researchers or fostering
              diversity in our team.
            </p>

            <Row className="obj-desc">• Impact</Row>
            <p className="desc">
              Our work is driven by the desire to solve global health challenges.
            </p>
          </section> */}
        </div>
      ) : (
        // Desktop version content
        <div className="programsCont3D">
          <Row className="programRow1">
            <h3 className="redHeaderHome">Research Programs</h3>
            <p className="img_des1">
              We model pulmonary surfactant (PS) at the molecular level using
              various computational techniques to understand the different
              components of pulmonary surfactant to advance human health.
            </p>
            <p className="learnProject">
              Click to learn more about each ongoing program.
            </p>

            {programsHome.map((program, i) => (
              <Col
                xs={12}
                sm={6}
                md={6}
                lg={6}
                xl={3}
                className="programCont"
                key={i}
              >
                <a href="/Research" className="linkImage">
                  <div className="mag">
                    <Image
                      src={`/images/HomePics/program${i + 1}Home.png`}
                      alt={`${program.alt}`}
                      className={
                        i === 0
                          ? "ImageProgram img-fluid circle fix"
                          : "ImageProgram img-fluid circle"
                      }
                    />
                  </div>
                  <p className="programHome">
                    <i
                      className={`bi bi-${i + 1}-square number`}
                      aria-label={`Program ${i + 1}`}
                    ></i>
                    {`${program.title}`}
                  </p>
                </a>
              </Col>
            ))}
            <div className="bottomPrograms"></div>
          </Row>

          <Row className="justify-content-center programRow">
            <Col xs="auto" className="d-flex justify-content-center">
              <Image
                src={`/images/HomePics/program_pulmo.png`}
                alt="Surfactant System"
                className="img-fluid bigimage"
              />
            </Col>
            <p className="img_des">Pulmonary Surfactant System</p>
            <p className="obj">
              At the COMBINE Lab, we believe that real innovation comes from
              seeing the world differently and breaking down barriers to
              knowledge. Our approach goes beyond traditional perspectives,
              embracing a future where everyone can contribute to advancing
              science, regardless of physical limitations.
            </p>
          </Row>

          {/* <section className="desc-sec">
            <Row className="obj-desc">• Innovation</Row>
            <p className="desc">
              We push the boundaries of what’s possible, using computational
              techniques to decode the complexities of biological interfaces and
              contribute to the improvement of human health.
            </p>

            <Row className="obj-desc">• Inclusivity</Row>
            <p className="desc">
              We are committed to making STEM accessible to all, whether through
              our multisensory tools for blind researchers or fostering
              diversity in our team.
            </p>

            <Row className="obj-desc">• Impact</Row>
            <p className="desc">
              Our work is driven by the desire to solve global health challenges.
            </p>
          </section> */}
        </div>
      )}
    </>
  );
}